/* eslint-disable no-useless-catch */
import type { CurrentUser } from '@cevo/gfinity-api-sdk'
import { AdminEndpoint, GfinityApi, UsersEndpoint } from '@cevo/gfinity-api-sdk'
import Cookies from 'js-cookie'
import { defineStore } from 'pinia'
import { usePreferencesStore } from './preferences'
import { useAppStore } from '.'

export const useUserStore = defineStore('user', () => {
  const { t } = useI18n()
  const appStore = useAppStore()
  const preferencesStore = usePreferencesStore()

  const loggedUser = ref<CurrentUser | null>(null)
  const token = ref<string | undefined | null>(null)
  const refreshTokenInterval = ref<NodeJS.Timer | void | null>(null)
  const permissions = ref<Record<string, string[]> | null>(null)
  const tenants = ref<string[]>([])

  async function login(email: string, password: string, tenant?: string) {
    try {
      const response = await AdminEndpoint.login(email, password, tenant)
      const { data } = response.data

      Cookies.set('uap-token', data.token, {
        expires: new Date(data.expires),
      })

      Cookies.set('uap-tenants', JSON.stringify(data.tenants), {
        expires: new Date(data.expires),
      })

      GfinityApi.setBearerToken(data.token)
      token.value = data.token
      tenants.value = data.tenants
      loggedUser.value = data.user
      await refreshToken()
    }
    catch (err: any) {
      throw err
    }
  }

  async function getProfile() {
    try {
      const _token = Cookies.get('uap-token')
      if (!_token)
        throw new Error(t('validation.invalidToken'))

      GfinityApi.removeBearerToken()
      GfinityApi.setBearerToken(_token)

      const userId = userDecoder(_token)
      const tenant = getUserTenant(userId)

      appStore.setTenant(tenant)
      token.value = _token

      const { data } = await UsersEndpoint.getCurrentUser()

      loggedUser.value = data.data
      permissions.value = permissionDecoder(_token) ?? null
      tenants.value = JSON.parse(Cookies.get('uap-tenants') ?? '') ?? []
      await refreshToken()
    }
    catch (err: any) {
      loggedUser.value = null
      permissions.value = null
      token.value = null
      Cookies.remove('uap-token')
      GfinityApi.removeBearerToken()
      throw err
    }
  }

  async function refreshToken() {
    const _token: string | undefined = Cookies.get('uap-token')
    const intervalDuration = 1000 * 60 * 10
    try {
      if (_token) {
        refreshTokenInterval.value = setInterval(async () => {
          const uapToken: string | undefined = Cookies.get('uap-token')
          if (uapToken) {
            const { data } = await UsersEndpoint.refreshToken()

            GfinityApi.removeBearerToken()
            GfinityApi.setBearerToken(data.data.token)
            // Store
            token.value = data.data.token

            if (data.data.token === uapToken) {
              console.error({
                error: 'JWT Token didn`t updated',
                newToken: data.data.token,
                oldToken: uapToken,
              })
            }

            Cookies.remove('uap-token')
            Cookies.set('uap-token', data.data.token, {
              expires: new Date(data.data.expires),
            })
            // Update Tenant Cookie with new expiration date
            const tenants = Cookies.get('uap-tenants') ?? ''
            Cookies.set('uap-tenants', tenants, {
              expires: new Date(data.data.expires),
            })
            permissions.value = permissionDecoder(_token) ?? null
          }
        }, intervalDuration)
      }
      else {
        loggedUser.value = null
        permissions.value = null
        token.value = null
        clearRefreshTokenInterval()
        Cookies.remove('uap-token')
        GfinityApi.removeBearerToken()
        throw new Error(t('validation.invalidToken'))
      }
    }
    catch (err) {
      loggedUser.value = null
      permissions.value = null
      token.value = null
      Cookies.remove('uap-token')
      GfinityApi.removeBearerToken()
      clearRefreshTokenInterval()
    }
  }

  function clearRefreshTokenInterval() {
    if (refreshTokenInterval.value)
      clearInterval(refreshTokenInterval.value)
  }

  function getUserTenantKey(userId?: string) {
    if (loggedUser.value)
      return `uap-tenant-${loggedUser.value.id ?? userId}`
    if (userId)
      return `uap-tenant-${userId}`
    return ''
  }

  function getUserTenant(userId?: string) {
    const key = getUserTenantKey(userId)
    if (!key)
      return null
    return localStorage.getItem(key)
  }

  function logout() {
    loggedUser.value = null
    permissions.value = null
    token.value = null
    preferencesStore.clearLanguages()
    clearRefreshTokenInterval()
    Cookies.remove('uap-token')
    GfinityApi.removeBearerToken()
  }

  return {
    clearRefreshTokenInterval,
    getProfile,
    getUserTenant,
    getUserTenantKey,
    loggedUser,
    login,
    logout,
    permissions,
    refreshToken,
    tenants,
    token,
  }
})
