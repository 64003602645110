import * as jose from 'jose'

export function permissionDecoder(jwt: string) {
  const decodedJwtData = jose.decodeJwt(jwt)
  return decodedJwtData?.permissions as Record<string, string[]> | undefined
}

export function userDecoder(jwt: string) {
  const decodedJwtData = jose.decodeJwt(jwt)
  return decodedJwtData?.userId as string | undefined
}
