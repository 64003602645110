import type { ConfigItem } from '@cevo/gfinity-api-sdk'
import { ConfigEndpoint } from '@cevo/gfinity-api-sdk'
import { acceptHMRUpdate, defineStore } from 'pinia'

export const usePreferencesStore = defineStore('preferences', () => {
  const languages = ref<ConfigItem & {
    value: string[]
  } | null>(null)

  const virtualCurrency = ref('ATH')

  async function loadLanguages() {
    try {
      const { data } = await ConfigEndpoint.getConfigItem('in-app/languages')
      languages.value = data.data as ConfigItem & { value: string[] }
    }
    catch (err: any) {
      console.error(err)
    }
  }

  function updateLanguage(_languages: string[]) {
    if (languages.value)
      languages.value = { ...languages.value, value: _languages }
  }

  function clearLanguages() {
    languages.value = null
  }

  function setVirtualCurrency(currency: string) {
    virtualCurrency.value = currency
  }

  return {
    clearLanguages,
    languages,

    loadLanguages,
    setVirtualCurrency,
    updateLanguage,
    virtualCurrency,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(usePreferencesStore, import.meta.hot))
