import { GfinityApi } from '@cevo/gfinity-api-sdk'
import type { App } from 'vue'
import { acceptHMRUpdate, createPinia, defineStore } from 'pinia'
import { useUserStore } from './users'

export function installPinia(app: App) {
  const pinia = createPinia()
  app.use(pinia)
}

export const useAppStore = defineStore('app', () => {
  const userStore = useUserStore()
  const tenantRouter = useTenantRouter()
  const initialLoading = ref(true)
  const isDrawerShown = ref(false)
  const tenant = ref<string>()

  function setInitialLoading(v: boolean) {
    initialLoading.value = v
  }

  function setDrawerShown(v: boolean) {
    isDrawerShown.value = v
  }

  function setTenant(_tenant?: string | null) {
    if (!_tenant)
      return

    GfinityApi.setTenantHeader(decodeURIComponent(_tenant))
    tenant.value = decodeURIComponent(_tenant)

    const lsTenant = userStore.getUserTenantKey()
    if (lsTenant)
      localStorage.setItem(lsTenant, decodeURIComponent(_tenant))
  }

  function onChangeTenant(value: string) {
    if (value === tenant.value)
      return

    setTenant(value)
    // we should redirect to the home page because we don't know
    // if the user has access to the page he was on
    tenantRouter.reload('/')
  }

  return {
    initialLoading,
    isDrawerShown,
    onChangeTenant,

    setDrawerShown,
    setInitialLoading,
    setTenant,
    tenant,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot))
