import { useAppStore } from '~/store'

type Route = string | { path: string, [x: string]: unknown }
type RouteLocationRaw = string | { path: string, [x: string]: unknown }

export function useTenantRouter() {
  const route = useRoute()
  const router = useRouter()
  const appStore = useAppStore()
  const tenant = computed(() => appStore.tenant ? encodeURIComponent(appStore.tenant) : '')

  const push = (to: Route | RouteLocationRaw) => {
    if (typeof to === 'string') {
      return router.push(`/${tenant.value}${to}`)
    }
    else {
      const { path, ...rest } = to
      return router.push({ path: `/${tenant.value}${path}`, ...rest })
    }
  }

  const replace = (to: Route) => {
    if (typeof to === 'string') {
      return router.replace(`/${tenant.value}${to}`)
    }
    else {
      const { path, ...rest } = to
      return router.replace({ path: `/${tenant.value}${path}`, ...rest })
    }
  }

  const resolve = (to: RouteLocationRaw) => {
    if (typeof to === 'string')
      return router.resolve({ path: `/${tenant.value}${to}` })

    const { path, ...rest } = to
    return router.resolve({ path: `/${tenant.value}${path}`, ...rest })
  }

  const back = router.back
  const go = router.go

  const routePath = (path: string) => `/${tenant.value}${path}`

  const reload = (to: string): void => {
    window.location.replace(`/${tenant.value}${to}`)
  }

  const getTenant = (url?: string) => {
    if (!url)
      url = route.fullPath

    if (url === '/')
      return null

    const nextTenant = url.split('/')
    if (nextTenant.length <= 1)
      return null

    return (nextTenant[0] === '' ? nextTenant[1] : nextTenant[0])?.replace('%2F', '/') ?? null
  }

  return { back, getTenant, go, push, reload, replace, resolve, routePath }
}
